import * as React from "react";
import * as Sentry from "@sentry/react";
import clsx from "clsx";
import { ReactNode, useEffect, useRef } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";

import media from "app/styles/media";
import DashboardHooksCall from "app/components/DashboardHooksCall";
import Announcements from "app/views/Announcements";
import { useProfile } from "app/providers/ProfileProvider";
import { TourGuideProvider } from "app/providers/TourGuideProvider";
import Text from "app/styles/Text";
import {
  DASHBOARD_BODY_PADDING_MOBILE_X,
  DASHBOARD_BODY_PADDING_MOBILE_Y,
  DASHBOARD_BODY_PADDING_X,
  DASHBOARD_BODY_PADDING_Y,
  DASHBOARD_HEADER_HEIGHT,
  DASHBOARD_MOBILE_NAV_HEIGHT,
  DASHBOARD_SIDEBAR_WIDTH,
} from "app/constants/variables";

import { ReactComponent as ArrowRightIcon } from "app/assets/icons/arrow-right.svg";
import { ReactComponent as InfoFilledIcon } from "app/assets/icons/info-circle-alt.svg";

import LoaderContainer from "./ui/LoaderContainer";
import ErrorWidget from "./ui/ErrorWidget";
import AccountHalt from "app/components/AccountHalt";
import { useActiveProfile } from "app/providers/ActiveProfileProvider";
import { useVerificationStatus } from "app/providers/VerificationStatusProvider";
import { useQueryClient } from "@tanstack/react-query";

const DashboardErrorWidget = (props: any) => (
  <ErrorWidget {...props} dashboard />
);

interface Props {
  header: ReactNode;
  sidenav: ReactNode;
  mobilenav: ReactNode;
}

export default function DashboardLayout(props: Props) {
  const { header, sidenav, mobilenav } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  const activeProfile = useActiveProfile();

  const location = useLocation();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scroll(0, 0);
    }
  }, [location.pathname]);

  const profile = useProfile();

  const isUserPendingDeletion = profile?.status === "pending";

  const verificationStatus = useVerificationStatus();

  const queryClient = useQueryClient();

  return (
    <>
      <Container>
        <div
          tw="h-0 scale-95 bg-[#FFF2A9] px-0  flex items-center justify-between sticky top-0 z-40 transition-[height,transform,padding] overflow-hidden"
          css={[
            !queryClient.isFetching(["verification-status"]) &&
              activeProfile?.profile_type === "business" &&
              verificationStatus?.status !== "verified" &&
              tw`h-[44px] scale-100 px-[32px] tablet:px-[16px]`,
            activeProfile?.profile_type === "business" &&
              verificationStatus?.status === "in_review" &&
              tw`bg-[#E5E5E5]`,
          ]}
        >
          <div tw="flex gap-[8px] invisible">
            <InfoFilledIcon />
            <Text semiBold tw="flex-shrink-0 text-black95">
              {verificationStatus?.status === "in_review"
                ? "Verification in progress!"
                : "Activate transactions!"}
            </Text>
          </div>
          <div>
            <Text tw="tablet:hidden flex-shrink-0 text-black95">
              {" "}
              {verificationStatus?.status === "in_review"
                ? "We’ve received your details and are reviewing your documents. Thank you for your patience."
                : verificationStatus?.status === "in_progress"
                ? "Your business is being verified, check back soon."
                : "Verify your business to start performing transactions seamlessly."}
            </Text>
          </div>

          {verificationStatus?.status !== "in_progress" && (
            <Link
              to={
                verificationStatus?.status === "in_review"
                  ? "/profile/verification-review"
                  : "/business-verification"
              }
              tw="text-[14px] font-[600] flex gap-[8px] items-center flex-shrink-0 text-black95"
            >
              {verificationStatus?.status === "in_review"
                ? "Check status"
                : "Verify now"}
              <ArrowRightIcon tw="w-[20px] h-[20px]" />
            </Link>
          )}
        </div>

        <div
          className="header"
          css={
            !queryClient.isFetching(["verification-status"]) &&
            activeProfile?.profile_type === "business" &&
            verificationStatus?.status !== "verified" &&
            tw`!top-[44px]`
          }
        >
          {header}
        </div>

        <section className="body">
          {!isUserPendingDeletion && (
            <aside
              className="sidebar"
              css={
                activeProfile?.profile_type === "business" &&
                verificationStatus?.status !== "verified" &&
                tw`!top-[108px]`
              }
            >
              {sidenav}
            </aside>
          )}

          <Sentry.ErrorBoundary
            fallback={DashboardErrorWidget}
            beforeCapture={(scope) => {
              scope.setTag("location", "dashboard");
            }}
          >
            <main
              className={clsx("content", {
                "content-is-business":
                  activeProfile?.profile_type === "business",
              })}
              ref={contentRef}
            >
              {isUserPendingDeletion ? (
                <AccountHalt />
              ) : (
                <>
                  {/* need to move this guys outa here and pass em as props */}
                  <Announcements />
                  <DashboardHooksCall>
                    <TourGuideProvider>
                      <React.Suspense
                        fallback={<LoaderContainer screen loading />}
                      >
                        <Outlet />
                      </React.Suspense>
                    </TourGuideProvider>
                  </DashboardHooksCall>
                </>
              )}
            </main>
          </Sentry.ErrorBoundary>
        </section>

        {!isUserPendingDeletion && <footer>{mobilenav}</footer>}
      </Container>
    </>
  );
}

const Container = styled.section`
  ${tw`w-full min-h-dvh h-full flex flex-col`};

  ${tw`bg-greyScale05`};

  ${tw`dark:bg-black`};

  > .header {
    min-height: ${DASHBOARD_HEADER_HEIGHT}px;
    position: sticky;
    top: 0;
    display: flex;
    ${tw`z-40 border-b`};
    ${tw`bg-white `};

    ${tw`dark:bg-black dark:border-b-white12`};
  }

  > .body {
    ${tw`flex-grow w-full flex`};
    height: calc(100% - ${DASHBOARD_HEADER_HEIGHT}px);

    ${media.tablet} {
      height: calc(
        100% - ${DASHBOARD_HEADER_HEIGHT}px - ${DASHBOARD_MOBILE_NAV_HEIGHT}px
      );
    }
  }

  > .body > .sidebar {
    width: ${DASHBOARD_SIDEBAR_WIDTH}px;
    height: 100%;
    position: sticky;
    top: ${DASHBOARD_HEADER_HEIGHT}px;
    flex-shrink: 0;

    ${tw`tablet:hidden`};
  }

  > .body > .content {
    ${tw`w-full h-full overflow-x-hidden overflow-y-auto max-w-[1178px] mx-auto`};

    padding-left: ${DASHBOARD_BODY_PADDING_X}px;
    padding-right: ${DASHBOARD_BODY_PADDING_X}px;

    padding-top: ${DASHBOARD_BODY_PADDING_Y}px;
    /* padding-bottom: ${DASHBOARD_BODY_PADDING_Y}px; */

    ${media.tablet} {
      padding-left: ${DASHBOARD_BODY_PADDING_MOBILE_X}px;
      padding-right: ${DASHBOARD_BODY_PADDING_MOBILE_X}px;

      padding-top: ${DASHBOARD_BODY_PADDING_MOBILE_Y}px;
      padding-bottom: ${DASHBOARD_BODY_PADDING_MOBILE_Y}px;
    }

    /* &::-webkit-scrollbar {
      display: none;
    } */
  }

  > .body > .content.content-is-business {
    ${tw`max-w-[840px]`}
  }

  > footer {
    flex-shrink: 0;
    height: ${DASHBOARD_MOBILE_NAV_HEIGHT}px;
    position: sticky;
    bottom: 0;
    z-index: 30;

    ${tw`w-full hidden tablet:block`};
  }
`;
